import React, { Component } from 'react';
import { debounce } from 'lodash';
import { formatMoney, formatPercent, extractValue } from '../utility';
import { localPatchJSON, extractErrorMessage } from '../fetch-local';

export default class LineItemPromoInformation extends Component {
  constructor(props) {
    super(props);
    this.setPromoInfoDebounced = debounce(this.setPromoInfo, 1000);
    // generate a random suffix for label ids
    let randomSuffix = Math.random()
      .toString(36)
      .substring(7);
    this.state = { promoFieldsSaved: false, formLabelSuffix: randomSuffix };
  }

  componentWillUnmount() {
    this.setPromoInfoDebounced.cancel();
  }

  onChange = event => {
    const { props } = this;
    const target = event.target;
    const value = extractValue(target);
    const name = target.name;

    const newLineItem = {
      ...props,
      data: {
        ...props.data,
        [name]: value,
      },
    };

    props.onPricingUpdate(newLineItem);
  };

  setPromoInfo() {
    const { props } = this;

    props.onPricingUpdate({ ...props, isLoading: true });

    localPatchJSON(props.links.setPrice, {
      line_item: {
        promotion_discount: props.data.promotionDiscount,
        individual_ship_promotion: props.data.individualShipPromotion,
        promo_discount_code: props.data.promoDiscountCode,
        product_margin_override: props.data.productMarginOverride,
      },
    })
      .then(data => {
        props.onPricingUpdate(data.lineItem, data.order);
        this.setState({ promoFieldsSaved: true });
      })
      .catch(error => {
        extractErrorMessage(error, 'There was an issue').then(error => {
          props.onPricingUpdate({ ...props, error });
        });
      });
  }

  componentDidUpdate(prevProps) {
    const shouldUpdatePromoFields = [
      'promotionDiscount',
      'individualShipPromotion',
      'promoDiscountCode',
      'productMarginOverride',
    ].some(property => {
      return prevProps.data[property] !== this.props.data[property];
    });

    if (shouldUpdatePromoFields) {
      this.setPromoInfoDebounced();
    }
  }

  render() {
    const {
      individualShipPromotion,
      individualShipPromotionMinimum,
      promotionDiscount,
      promoDiscountCode,
      productMarginOverride,
    } = this.props.data;

    const { currentUser } = this.props.meta;
    const showProductMarginOverride =
      currentUser.canOverrideProductMargin &&
      !this.props.data.promoProduct &&
      !this.props.data.customItem;

    const productMarginOverrideField = showProductMarginOverride && (
      <div className="mbl">
        <label
          htmlFor={`product-margin-override-${this.state.formLabelSuffix}`}
          className="mtn better-label"
        >
          Override Product Margin
          <span
            className="mtn hint--bottom mls inline-block"
            aria-label={`Original Margin: ${this.props.data.originalProductMargin}%`}
          >
            <i className="fa fa-info-circle"></i>
          </span>
        </label>{' '}
        <div className="txt-nowrap prs">
          <input
            id={`product-margin-override-${this.state.formLabelSuffix}`}
            placeholder={this.props.data.originalProductMargin}
            value={productMarginOverride || ''}
            type="number"
            step="0.1"
            min="20"
            max="100"
            style={{ minWidth: '12rem' }} // to keep consistent width across all promo inputs
            name="productMarginOverride"
            onChange={this.onChange}
            className="product-margin-override"
          />
          {' %'}
        </div>
      </div>
    );

    const promoView = currentUser.canUpdatePricing ? (
      <div
        className="form-compact form-light form-light--bordered"
        data-test-promo-fields-saved={this.state.promoFieldsSaved}
      >
        <div className="mbs">
          <label
            htmlFor={`ind-ship-promo-${this.state.formLabelSuffix}`}
            className="mtn better-label"
          >
            Ind. Ship Promo
          </label>
          <div className="txt-nowrap prs">
            <div className="with-prefix">
              <div className="input-prefix">{'$'}</div>
              <input
                id={`ind-ship-promo-${this.state.formLabelSuffix}`}
                value={individualShipPromotion || ''}
                min={individualShipPromotionMinimum}
                step={0.01}
                type="number"
                name="individualShipPromotion"
                onChange={this.onChange}
                className="individual-ship-promotion"
              />
            </div>
          </div>
        </div>

        <div className="mbs">
          <label
            htmlFor={`promo-discount-${this.state.formLabelSuffix}`}
            className="mtn better-label"
          >
            Promo Discount
          </label>
          <div className="txt-nowrap prs">
            <input
              id={`promo-discount-${this.state.formLabelSuffix}`}
              placeholder="0"
              value={promotionDiscount || ''}
              step={1}
              type="number"
              name="promotionDiscount"
              onChange={this.onChange}
              className="promotional-discount"
            />
            {' %'}
          </div>
        </div>

        <div className="mbs">
          <label
            htmlFor={`promo-code-${this.state.formLabelSuffix}`}
            className="mtn better-label"
          >
            Promo Code
          </label>
          <input
            id={`promo-code-${this.state.formLabelSuffix}`}
            value={promoDiscountCode || ''}
            type="text"
            name="promoDiscountCode"
            onChange={this.onChange}
            className="promo-discount-code"
          />
        </div>
        {productMarginOverrideField}
      </div>
    ) : (
      <div>
        <dl className="dl-grid">
          <dt>Individual Ship Promo:</dt>
          <dd className="txt-right">{formatMoney(individualShipPromotion)}</dd>

          <dt>Promo. Discount:</dt>
          <dd className="txt-right">{formatPercent(promotionDiscount)}</dd>

          <dt>Promo. Discount Code:</dt>
          <dd className="txt-right">{promoDiscountCode}</dd>

          <dt>Product Margin Override:</dt>
          <dd className="txt-right">{productMarginOverride}</dd>
        </dl>
      </div>
    );

    if (currentUser.canViewPromoInfo) {
      return promoView;
    } else {
      return null;
    }
  }
}
