import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import IconSvg from './IconSvg';
import { Color } from '../BrandColor';
import CollapsibleNotes from './CollapsibleNotes';
import Notification from './notification';

Modal.setAppElement('.main');

export default function ShippingInfoModal(props: {
  shipToAddress: {
    address: string;
    shippingName: string;
    address2: string;
    businessName: string;
    locality: string;
    region: string;
    postalCode: string;
  };
  shipFromAddress: {
    businessName: string;
    address: string;
    address2: string;
    locality: string;
    region: string;
    postalCode: string;
  };
  hideShippingAddress: boolean;
  hiddenShippingAddressPlaceholder: string;
  printBoxLabel: boolean;
  rush: boolean;
  partialBulkShip: boolean;
  notes: [];
  shipDate: string;
  deliveryDate: string;
  shippingService: string;
  shippingMethod: string;
  shipWithAccountNumber: any;
  shippingAccountNumber: string;
  shippingReferenceNumber: string;
  shippingCost: {} | null | undefined;
  shipFromDiffAddress: boolean;
  shippingMethodLabel: string;
  trackingNumber: string;
  showWarningAboutShippingNotes: boolean;
  showShippingNotes: boolean;
  greekHouseOpportunity: boolean;
  greekHouseNumber: string;
  showGreekHouseOpportunityLabel: boolean;
  showGreekHouseNumberLabel: boolean;
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hasCopied, setHasCopied] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setHasCopied(false), 500);
    return () => clearTimeout(timeout);
  }, [hasCopied]);

  function handleOpen() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const copyableSpan = (text: string) => {
    return (
      <span
        role="button"
        tabIndex={0}
        onClick={() => handleCopy(text)}
        onKeyPress={event => handleKeyPress(event, props.shipToAddress.address)}
        style={{ cursor: 'pointer' }}
      >
        {text}
      </span>
    );
  };

  const handleCopy = text => {
    navigator.clipboard.writeText(text);
    setHasCopied(true);
  };

  const handleKeyPress = (event, text) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleCopy(text);
    }
  };

  const copyHelpfulHint = (
    <p className="notification notification--notice notification--compact txt-small txt-notify mbm">
      <i className="fa fa-lightbulb-o mrs" />
      <span className="txt-em">Click on any part of an address to copy.</span>
    </p>
  );

  const nonstandardShippingMethodLabel = props.shippingMethodLabel !==
    'Standard Shipping' && (
    <span className="label label--spaced label--notice mbl mts">
      {props.shippingMethodLabel}
    </span>
  );

  const greekHouseOppLabel = (
    <span className="label label--spaced label--notice mbl mts">
      Greek House
    </span>
  );

  const greekHouseNumberLabel = (
    <span className="label label--spaced label--notice mbl mts">
      Greek House: {props.greekHouseNumber}
    </span>
  );

  // This warning is temporary and can be deleted after all current orders are through the system.
  const shippingNotesWarning = props.showWarningAboutShippingNotes &&
    props.showShippingNotes && (
      <div className="mbl">
        <Notification type="alert" small>
          This order closed prior to the new shipping notes feature going live.
          <br></br>
          <strong>
            Please be sure to look at the Order Discussion to see if there are
            any comments about shipping.
          </strong>
        </Notification>
      </div>
    );

  const shipToAddressView = (
    <div>
      <strong> Ship To:</strong>
      <p className="mvn">{copyableSpan(props.shipToAddress.shippingName)}</p>
      <address>
        {props.shipToAddress.businessName && (
          <>
            {copyableSpan(props.shipToAddress.businessName)}
            <br />
          </>
        )}
        {copyableSpan(props.shipToAddress.address)}
        <br />
        {props.shipToAddress.address2 && (
          <>
            {copyableSpan(props.shipToAddress.address2)}
            <br />
          </>
        )}
        {copyableSpan(props.shipToAddress.locality)},{' '}
        {copyableSpan(props.shipToAddress.region)}{' '}
        {copyableSpan(props.shipToAddress.postalCode)}
      </address>
      {hasCopied && (
        <Notification type="success" small float>
          Copied to clipboard!
        </Notification>
      )}
    </div>
  );

  const shipFromAddressView = (
    <div className="mtl">
      <strong> Ship From:</strong>
      <p className="mvn">{copyableSpan(props.shipFromAddress.businessName)}</p>
      <address>
        {copyableSpan(props.shipFromAddress.address)}
        <br />
        {props.shipFromAddress.address2 && (
          <>
            {copyableSpan(props.shipFromAddress.address2)}
            <br />
          </>
        )}
        {copyableSpan(props.shipFromAddress.locality)},{' '}
        {copyableSpan(props.shipFromAddress.region)}{' '}
        {copyableSpan(props.shipFromAddress.postalCode)}
      </address>
      {hasCopied && (
        <Notification type="success" small float>
          Copied to clipboard!
        </Notification>
      )}
    </div>
  );

  const hiddenShippingAddressView = props.hideShippingAddress && (
    <div className="mtl">
      <p className="txt-muted2 txt-em mvn">
        {props.hiddenShippingAddressPlaceholder}
      </p>
    </div>
  );

  return (
    <>
      <button className="button-naked mtl" type="button" onClick={handleOpen}>
        <i className="fa fa-info-circle notify mls"></i>
        <span className="is-hidden-visually">View More</span>
      </button>

      <Modal
        isOpen={modalIsOpen}
        className="react-modal"
        overlayClassName="react-modal-overlay"
        contentLabel="See Shipping Details"
        closeTimeoutMS={200}
      >
        <div className="react-modal-header flex-rows flex-rows--space-b flex-rows--center-v">
          <h4 className="react-modal-header__title">Shipping Details</h4>
          <button className="button-naked" type="button" onClick={closeModal}>
            <IconSvg icon="close" color={Color.Gray} />
          </button>
        </div>

        <div className="react-modal-body react-modal-content">
          {shippingNotesWarning}
          {props.printBoxLabel && (
            <span className="label label--notice label--spaced mbl mts">
              Print Box Label
            </span>
          )}
          {nonstandardShippingMethodLabel}
          {props.greekHouseOpportunity &&
            props.showGreekHouseOpportunityLabel &&
            greekHouseOppLabel}
          {props.greekHouseNumber &&
            props.showGreekHouseNumberLabel &&
            greekHouseNumberLabel}
          {props.rush && (
            <span className="label label--spaced mbl mts">Rush</span>
          )}
          {props.partialBulkShip && (
            <span className="label label--spaced mbl mts">
              Partial Bulk Ship
            </span>
          )}
          {props.notes.length > 0 && props.showShippingNotes && (
            <section className="bg-light-yellow bg-light-yellow--bordered txt-warning mbl">
              <CollapsibleNotes
                notes={props.notes}
                header="Shipping Notes"
              ></CollapsibleNotes>
            </section>
          )}
          <section className="stack stack--x-small">
            <p>
              <strong>Ship Date: </strong>
              {props.shipDate}
            </p>
            <p>
              <strong>Delivery Date: </strong>
              {props.deliveryDate}
            </p>
            <p>
              <strong>Shipping Service: </strong>
              {copyableSpan(props.shippingService)}
            </p>
            {props.shipWithAccountNumber && (
              <p>
                <strong>Shipping Account #: </strong>
                {copyableSpan(props.shippingAccountNumber)}
              </p>
            )}
            {props.shippingReferenceNumber && (
              <p>
                <strong>Shipping Reference #: </strong>
                {copyableSpan(props.shippingReferenceNumber)}
              </p>
            )}
            {props.shippingCost && (
              <p>
                <strong>Rush Shipping Costs: </strong>
                {props.shippingCost}
              </p>
            )}
            {props.trackingNumber && (
              <p>
                <strong>Tracking #: </strong>
                {copyableSpan(props.trackingNumber)}
              </p>
            )}
          </section>

          <section className="mvl border-top border-top--light pvl">
            {(props.shipFromDiffAddress || !props.hideShippingAddress) &&
              copyHelpfulHint}
            {props.hideShippingAddress
              ? hiddenShippingAddressView
              : shipToAddressView}
            {props.shipFromDiffAddress && shipFromAddressView}
          </section>
        </div>
      </Modal>
    </>
  );
}
