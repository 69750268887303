import React from 'react';
import ColorList from './ColorList';

const LineItemColorChanges = props => {
  const { data } = props;
  const locations = data.locations || [];

  const colors = locations.reduce((colorLists, location) => {
    if (
      location.nonstandardLocation ||
      location.decorationType === 'flocking'
    ) {
      return colorLists;
    }
    return [...colorLists, <ColorList key={location.id} {...location} />];
  }, []);

  return colors.length > 0 && <div>{colors}</div>;
};

export default LineItemColorChanges;
