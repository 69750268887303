import React from 'react';
import PropTypes from 'prop-types';
import Color from './Color';

const ColorList = props => {
  const colors = props.colors.map(color => (
    <li key={`${color.name}${color.hexColor}`}>
      <Color {...color} />
    </li>
  ));

  const name = props.name && <p className="mbn mtn txt-strong">{props.name}</p>;

  return (
    <React.Fragment>
      {name}
      <ul className="list-plain">{colors}</ul>
    </React.Fragment>
  );
};

ColorList.propTypes = {
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      hexColor: PropTypes.string,
    })
  ).isRequired,
  name: PropTypes.string,
};

export default ColorList;
