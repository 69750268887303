import * as Types from '../../base-types';
import {
  localGet,
  localPatchJSON,
  localDelete,
  localPostJSON,
} from '../../fetch-local';
import { mapKeys, snakeCase, isEmpty } from 'lodash';
import * as Permissions from './Permissions';
import { AttachmentUpdateType } from '../../attachment-types';

function transformPayload(o: object): object {
  return mapKeys(o, (_, key) => snakeCase(key));
}

interface ArtJob extends Types.ArtJob {
  permissions: Permissions.ArtJob;
}

interface Location extends Types.Location {
  permissions: Permissions.Location;
  revisions: Revision[];
  attachmentValidation: Types.AttachmentValidation;
  revisionAttachmentValidation: Types.AttachmentValidation;
  proofStatus: number;
}

interface LineItem extends Types.LineItem {
  permissions: Permissions.LineItem;
  attachmentValidation: Types.AttachmentValidation;
  compositePrimaryStatus: number;
  compositeSecondaryStatus: number;
}

interface Revision extends Types.Revision {
  permissions: Permissions.Revision;
}

export interface GetResponse {
  links: {
    order: string;
    locations: string;
  };
  artJob: ArtJob;
  locations: Location[];
  lineItems: LineItem[];
  order: Types.Order;
}

export async function get(uri: string): Promise<GetResponse> {
  return localGet(uri).then(
    ({ artJob, locations, lineItems, order, links }) => {
      return {
        artJob,
        locations,
        lineItems,
        order,
        links,
      };
    }
  );
}

export async function saveArtJob(artJob: Types.ArtJob): Promise<void> {
  const {
    allocatorNotes,
    greek,
    collegiate,
    uteesOriginalArt,
    printColorChange,
  } = artJob;

  const payload = {
    notes: allocatorNotes,
    greek,
    collegiate,
    uteesOriginalArt,
    printColorChange,
  };

  return await localPatchJSON(artJob.links.self, {
    art_job: transformPayload(payload),
  });
}

export async function submitArt(artJob: Types.ArtJob): Promise<void> {
  return await localPostJSON(artJob.links.artSubmission, {});
}

export async function saveLineItem(
  lineItem: Types.UpdatedLineItem
): Promise<UpdateLineItemResponse | null> {
  const { primaryImageUpdate, secondaryImageUpdate } = lineItem;

  let payload = {};
  if (primaryImageUpdate.type === AttachmentUpdateType.AddAttachment) {
    payload = { ...payload, compositePrimary: primaryImageUpdate.signedId };
  }
  if (secondaryImageUpdate.type === AttachmentUpdateType.AddAttachment) {
    payload = { ...payload, compositeSecondary: secondaryImageUpdate.signedId };
  }

  if (isEmpty(payload)) return null;

  return await localPatchJSON(lineItem.links.self, {
    line_item: transformPayload(payload),
  });
}

interface UpdateLocationPayload {
  decorationStyle: number;
  productionNotes: string;
  threadCount: string;
  printColorIds: number[];
  dimensions?: {
    width: number;
    height: number;
  };
  proof?: string;
}

interface UpdateLocationResponse {
  id: Types.Id;
  proof: Types.Attachment | null;
  proofStatus: number;
}

interface UpdateLineItemResponse {
  id: Types.Id;
  compositePrimary: Types.Attachment | null;
  compositeSecondary: Types.Attachment | null;
  compositePrimaryStatus: number;
  compositeSecondaryStatus: number;
}

export async function saveLocation(
  location: Types.UpdatedLocation
): Promise<UpdateLocationResponse> {
  const { proofUpdate } = location;

  const dimensions = location.dimensions
    ? { dimensions: location.dimensions }
    : {};

  let payload: UpdateLocationPayload = {
    productionNotes: location.productionNotes || '',
    decorationStyle: location.decorationStyle,
    threadCount: location.threadCount,
    printColorIds: location.colors.map(({ id }) => id),
    ...dimensions,
  };

  if (proofUpdate.type === AttachmentUpdateType.AddAttachment) {
    payload = { ...payload, proof: proofUpdate.signedId };
  }

  return await localPatchJSON(location.links.self, {
    location: transformPayload(payload),
  });
}

function artTierToPayload(artTier: Types.ArtTier): string {
  switch (artTier) {
    case Types.ArtTier.Original:
      return 'art_tier_original';
    case Types.ArtTier.NotSet:
      return 'art_tier_not_set';
    case Types.ArtTier.Signature:
      return 'art_tier_signature';
    case Types.ArtTier.Creative:
      return 'art_tier_creative';
    case Types.ArtTier.EComm:
      return 'art_tier_e_comm';
  }
}

export async function updateArtTier(
  artJob: Types.ArtJob,
  artTier: Types.ArtTier
): Promise<void> {
  const payload = {
    art_job: {
      art_tier: artTierToPayload(artTier),
    },
  };

  return await localPatchJSON(artJob.links.artJob, payload);
}

export async function updateCollegeMarks(
  artJob: Types.ArtJob,
  collegeMarks: boolean
): Promise<void> {
  const payload = { art_job: { college_marks: collegeMarks } };
  return await localPatchJSON(artJob.links.artJob, payload);
}

export async function purgeLocationProof(
  location: Types.UpdatedLocation
): Promise<UpdateLocationResponse> {
  return await localDelete(location.links.self);
}

export async function purgeLineItemComposite(
  lineItem: Types.UpdatedLineItem,
  compositeType: 'primary' | 'secondary'
): Promise<UpdateLineItemResponse> {
  return await localDelete(`${lineItem.links.self}?${compositeType}=true`);
}
