import React, { useState } from 'react';
import Select from 'react-select';
import CheckBoxField from './check-box-field';

interface Props {
  prefix: string;
  name: string;
  organizationType: string;
  nationwide: boolean;
  group: string;
  licensed: boolean;
  displayOnMarketing: boolean;
  royaltyPercentage: number;
  organizationTypes: Array<Object>;
  groups: Array<Object>;
  inactive: boolean;
  canEdit: boolean;
}

export default function OrganizationForm(props: Props) {
  const {
    prefix,
    name,
    organizationType,
    nationwide,
    group,
    licensed,
    displayOnMarketing,
    royaltyPercentage,
    organizationTypes,
    groups,
    inactive,
    canEdit,
  } = props;

  const [grouping, setGroup] = useState(group);
  const [selectedName, setName] = useState(name);
  const [selectedOrgType, setOrgType] = useState(organizationType);
  const [nationwideSet, toggleNationwide] = useState(nationwide || false);
  const [selectedRoyalty, setRoyaltyPercentage] = useState(royaltyPercentage);

  let displayOrganizationOptions = nationwide ? (
    <div>
      <Select
        name={prefix + '[group]'}
        options={groups}
        placeholder="Select a Group"
        value={grouping}
        onChange={() => {
          setGroup(this);
        }}
        simpleValue={true}
      />
    </div>
  ) : null;

  let inactiveSettingNotice = inactive ? (
    <p className="txt-muted txt-small mtn">
      Inactive has been set by a support member. Please contact support if you'd
      like to update this setting.
    </p>
  ) : (
    <p className="txt-muted txt-small mtn">
      Checking this box will automatically disable all associated opportunities
    </p>
  );

  return (
    <div className="l-section form-vertical form-full">
      <input type="hidden" name={prefix + '[group]'} value={group} />

      <div>
        <label>Name</label>
        <input
          type="text"
          name={prefix + '[name]'}
          value={selectedName}
          onChange={() => {
            setName(this);
          }}
          placeholder="Name"
        />
      </div>

      <div>
        <Select
          name={prefix + '[organization_type]'}
          options={organizationTypes}
          placeholder="Select a Type"
          value={selectedOrgType}
          simpleValue={true}
          onChange={setOrgType}
        />
      </div>

      <div className="mtl">
        <CheckBoxField
          id="nationwide"
          name={prefix + '[nationwide]'}
          label="Nationwide Organization"
          checked={nationwideSet}
          onChange={() => {
            toggleNationwide(this);
          }}
        />
      </div>

      {displayOrganizationOptions}

      <div>
        <CheckBoxField
          id="licensed"
          name={prefix + '[licensed]'}
          label="Licensed with Affinity"
          checked={licensed}
        />
      </div>
      <p className="txt-muted txt-small mtn">
        Checking this box will automatically submit artwork to Affinity for all
        orders associated with this organization.
      </p>

      <div className="mtl">
        <CheckBoxField
          id="display-on-marketing"
          name={prefix + '[display_on_marketing]'}
          label="Display on Marketing"
          checked={displayOnMarketing}
        />
      </div>

      <div className="mtl">
        <label>Royalty Percentage</label>
        <input
          name={prefix + '[royalty_percentage]'}
          value={selectedRoyalty}
          onChange={() => {
            setRoyaltyPercentage(this);
          }}
          placeholder="Royalty Percentage"
        />
      </div>

      <div className="mtl">
        <CheckBoxField
          id="inactive"
          name={prefix + '[inactive]'}
          label="Inactive"
          checked={inactive}
          disabled={canEdit ? '' : 'disabled'}
        />
      </div>
      {inactiveSettingNotice}
    </div>
  );
}
