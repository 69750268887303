import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { intersection } from 'lodash';
import Location from './location';
import classNames from 'classnames';
import tenant from '../tenant';
import Removable from './removable';

import CheckBoxField from './check-box-field';
import LocationsModel from '../stores/locations';

const GlobalData = window.GlobalData;

class Locations extends Component {
  static propTypes = {
    artJob: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    let { artJob } = this.props;
    let locations = artJob.locations.length > 0 ? artJob.locations : [];

    let isPromoProduct = artJob.promo_product;

    let apparelLocations = isPromoProduct ? [] : locations;
    let promoLocations = isPromoProduct ? locations : [];

    this.state = {
      apparelLocationsModel: new LocationsModel(apparelLocations),
      promoLocationsModel: new LocationsModel(promoLocations),
      isPromoProduct,
      isSubmitting: false,
      utees_original_art: artJob.utees_original_art,
    };
  }

  handlePromoProductToggle = event => {
    let isPromoProduct = event.target.checked;
    this.setState({ isPromoProduct });
  };

  componentWillMount() {
    this.state.apparelLocationsModel.subscribe(() => {
      this.setState({
        apparelLocationsModel: this.state.apparelLocationsModel,
      });
    });

    this.state.promoLocationsModel.subscribe(() => {
      this.setState({
        promoLocationsModel: this.state.promoLocationsModel,
      });
    });

    if (this.state.apparelLocationsModel.locations.length === 0) {
      this.addLocation();
    }
    if (this.state.promoLocationsModel.locations.length === 0) {
      this.addPromoLocation();
    }
  }

  deleteLocation(locationsModel, nonce) {
    if (this.state.isPromoProduct) {
      this.state.promoLocationsModel.removeLocation(nonce);
    } else {
      this.state.apparelLocationsModel.removeLocation(nonce);
    }
    this.disableSubmit();
  }

  renderLocation(enabled, isPromoProduct, locationsModel, location, index) {
    let prefix = `art_job[locations_attributes][${index}]`;

    let removableProps = {
      key: `location-${location.nonce}`,
      prefix: prefix,
      id: location.id,
      className: classNames({
        'flex-rows__item': true,
        'is-hidden': !enabled,
      }),
      prompt: 'Remove Location',
      removeFunc: this.deleteLocation.bind(
        this,
        locationsModel,
        location.nonce
      ),
    };

    let locationProps = {
      ...location,
      index,
      prefix,
      isPromoProduct,
      enabled,
      locationsModel,
    };
    return (
      <Removable {...removableProps}>
        <Location
          {...locationProps}
          canSelectNonstandardLocations={
            this.props.canSelectNonstandardLocations
          }
        />
      </Removable>
    );
  }

  renderApparelLocations() {
    let { apparelLocationsModel, isPromoProduct } = this.state;

    return apparelLocationsModel.locations.map(
      this.renderLocation.bind(
        this,
        !isPromoProduct,
        false,
        apparelLocationsModel
      )
    );
  }

  renderPromoLocations() {
    let { promoLocationsModel, isPromoProduct } = this.state;

    return promoLocationsModel.locations.map(
      this.renderLocation.bind(this, isPromoProduct, true, promoLocationsModel)
    );
  }

  locations() {
    return (
      <div className="flex-rows flex-rows--wrap">
        {this.renderApparelLocations()}
        {this.renderPromoLocations()}
      </div>
    );
  }

  renderDeletedLocation(enabled, location, index) {
    let prefix = `art_job[locations_attributes][100${index}]`;
    return (
      <fieldset disabled={!enabled}>
        <span className="hidden" key={'location-' + location.nonce}>
          <input type="hidden" name={prefix + '[id]'} value={location.id} />
          <input type="hidden" name={prefix + '[_destroy]'} value="1" />
        </span>
      </fieldset>
    );
  }

  renderDeletedApparelLocations() {
    let { apparelLocationsModel, isPromoProduct } = this.state;

    return apparelLocationsModel.deletedLocations.map(
      this.renderDeletedLocation.bind(this, !isPromoProduct)
    );
  }

  renderDeletedPromoLocations() {
    let { promoLocationsModel, isPromoProduct } = this.state;

    return promoLocationsModel.deletedLocations.map(
      this.renderDeletedLocation.bind(this, isPromoProduct)
    );
  }

  deletedLocations() {
    return (
      <div>
        {this.renderDeletedApparelLocations()}
        {this.renderDeletedPromoLocations()}
      </div>
    );
  }

  addPromoLocation = event => {
    if (event) {
      event.preventDefault();
    }
    this.state.promoLocationsModel.addLocation({
      decoration_type: 'promo',
    });
  };

  addLocation = event => {
    if (event) {
      event.preventDefault();
    }
    this.state.apparelLocationsModel.addLocation();
  };

  onSubmit = event => {
    if (this.state.isSubmitting) {
      event.preventDefault();
    }
    this.setState({ isSubmitting: true });
  };

  disableSubmit = () => {
    let locationsHaveDecorationType =
      this.state.apparelLocationsModel.locations.filter(
        l => l.decoration_type !== null
      ).length >= this.state.apparelLocationsModel.locations.length;
    return locationsHaveDecorationType || this.state.isPromoProduct
      ? false
      : true;
  };

  canEditLocationAttributes() {
    let { artJob } = this.props;
    let { isPreArtUploaded } = artJob;
    let { isBlocked } = artJob;

    let usersAlwaysAbleToEdit =
      intersection(GlobalData.currentUserRoles, [
        'uberadmin',
        'admin',
        'artist',
      ]).length > 0;

    let usersAbleToEdit =
      intersection(GlobalData.currentUserRoles, ['business_development_leader'])
        .length > 0;

    return (
      isPreArtUploaded ||
      usersAlwaysAbleToEdit ||
      (!isPreArtUploaded && usersAbleToEdit && !isBlocked)
    );
  }

  toggleUteesOriginalArt = e => {
    this.setState({ utees_original_art: e.target.checked });
  };

  render() {
    let { artJob } = this.props;

    let addButtonProps = {
      key: 'add_location',
      href: null,
      onClick: this.state.isPromoProduct
        ? this.addPromoLocation
        : this.addLocation,
      className: 'mvl button button--large button--full-res',
    };

    let { collectGreekRoyalties } = tenant;

    let { isPromoProduct } = this.state;

    let prefix = attr => `art_job[${attr}]`;

    let showGreekFields =
      collectGreekRoyalties && this.canEditLocationAttributes();

    let greekRoyaltyField = showGreekFields ? (
      <CheckBoxField
        id="greek"
        name={prefix('greek')}
        label="Greek?"
        className="mrm"
        checked={artJob.isGreek}
      />
    ) : null;

    let collegiateRoyaltyField = showGreekFields ? (
      <CheckBoxField
        id="collegiate"
        name={prefix('collegiate')}
        label="Collegiate?"
        className="mrm"
        checked={artJob.collegiate}
      />
    ) : null;

    let artCodeField = showGreekFields ? (
      <div className="form-full">
        <label htmlFor="art_code_field">
          UTees Original Art Code (if applicable):
        </label>
        <input
          id="art_code_field"
          name={prefix('art_code')}
          defaultValue={artJob.art_code}
        />
      </div>
    ) : null;

    let inkColorChangesField = this.canEditLocationAttributes() ? (
      <fieldset
        style={{ display: 'inline-block' }}
        className={classNames({ 'is-hidden': isPromoProduct })}
        disabled={isPromoProduct}
      >
        <CheckBoxField
          id="ink_changes"
          name={prefix('print_color_change')}
          label="Will there be a print color change between items?"
          className="mrm"
          checked={artJob.print_color_change}
        />
      </fieldset>
    ) : null;

    let submitButton = this.canEditLocationAttributes() ? (
      <div className="form-actions col-2 txt-right">
        <button
          type="submit"
          disabled={this.disableSubmit()}
          className="button button--large button--full-res"
          onClick={this.onSubmit}
        >
          {this.state.isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    ) : null;

    const promoProductCheckBoxIsDisabled =
      artJob && artJob.locations && artJob.locations.length > 0;

    return (
      <div>
        <div className="row">
          <div className="col-4">{artCodeField}</div>
        </div>
        <TransitionGroup>
          <CSSTransition
            classNames="slide-right"
            timeout={{ exit: 250, enter: 300 }}
            className="locations row row-dynamic"
          >
            <div>
              <label className="mbl">
                <input
                  type="checkbox"
                  disabled={promoProductCheckBoxIsDisabled}
                  checked={isPromoProduct}
                  onChange={this.handlePromoProductToggle}
                />{' '}
                Promo Product?
              </label>

              {this.locations()}

              <a {...addButtonProps}>
                <i className="fa fa-plus fa-5x" />
                <br />
                Add location
              </a>
            </div>
          </CSSTransition>
        </TransitionGroup>

        {this.deletedLocations()}

        <div className="row mtm">
          <div className="col-10 mts">
            {greekRoyaltyField}
            {collegiateRoyaltyField}
            {inkColorChangesField}
          </div>
          {submitButton}
        </div>

        <input
          type="hidden"
          name={prefix('promo_product')}
          value={isPromoProduct ? '1' : '0'}
        />
      </div>
    );
  }
}

export default Locations;
