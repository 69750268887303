import React, { useState } from 'react';
import Select from 'react-select';
import VirtualizedSelect from 'react-virtualized-select';
import { find, escapeRegExp } from 'lodash';
import Table from './Table';
import FilterSearch from './FilterSearch';
import {
  localPostJSON,
  localDelete,
  extractErrorMessage,
} from '../fetch-local';
import Loader from './loader';
import TrashButton from './TrashButton';
import Notification from './notification';

const buildOption = ({ id, name }) => ({
  value: id,
  label: name,
});

export default function AffinityCategorizationsApp(props) {
  const initialColumns = [
    {
      id: 'division',
      label: 'Division',
      value: ({ division }) => division.name,
      render: ({ division }) => division.name,
      cellProps: { className: 'txt-center' },
    },
    {
      id: 'department',
      label: 'Department',
      value: o => o.department && o.department.name,
      render: o => (o.department ? o.department.name : '-'),
      cellProps: { className: 'txt-center' },
    },
    {
      id: 'apparel_class',
      label: 'Apparel Class',
      value: o => o.apparelClass && o.apparelClass.name,
      render: o => (o.apparelClass ? o.apparelClass.name : '-'),
      cellProps: { className: 'txt-center' },
    },
    {
      id: 'affinityProductCategory',
      label: 'Affinity Product Category',
      value: o =>
        o.affinityProductCategory ? o.affinityProductCategory.name : '!!!',
      render: o =>
        o.affinityProductCategory ? (
          o.affinityProductCategory.name
        ) : (
          <em className="txt-muted txt-small">
            Affinity categorization no longer exists...
          </em>
        ),
      cellProps: { className: 'txt-center' },
    },
    {
      id: 'trash',
      label: '',
      value: () => null,
      render: o => (
        <React.Fragment>
          {!o.isLoading && <TrashButton onClick={() => onDelete(o)} />}
          <Loader isActive={o.isLoading} />
        </React.Fragment>
      ),
      cellProps: { className: 'txt-center txt-alert' },
      disableSorting: true,
    },
  ];

  const [division, setDivision] = useState(null);
  const [department, setDepartment] = useState(null);
  const [apparelClass, setApparelClass] = useState(null);
  const [affinityProductCategory, setAffinityProductCategory] = useState(null);
  const [columns, setColumns] = useState(initialColumns);
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [affinityCategorizations, setAffinityCategorizations] = useState(
    props.affinityCategorizations.map(a => ({ ...a, isLoading: false }))
  );

  const isInvalid = !division || !affinityProductCategory;

  const hydratedAffinityCategorizations = affinityCategorizations.map(
    ({
      id,
      affinityProductCategoryId,
      apparelClassId,
      departmentId,
      divisionId,
      links,
      isLoading,
    }) => {
      const division = find(props.divisions, o => o.id === divisionId);
      const department = find(props.departments, o => o.id === departmentId);
      const apparelClass = find(
        props.apparelClasses,
        o => o.id === apparelClassId
      );
      const affinityProductCategory = find(
        props.affinityProductCategories,
        o => o.id === affinityProductCategoryId
      );

      return {
        id,
        division,
        department,
        apparelClass,
        affinityProductCategory,
        links,
        isLoading,
      };
    }
  );

  const filteredAffinityCategorizations = hydratedAffinityCategorizations.filter(
    o => {
      const text = [
        o.division,
        o.department,
        o.apparelClass,
        o.affinityProductCategory,
      ]
        .map(v => (v ? v.name : ''))
        .join(' ');

      const q = escapeRegExp(query);
      return text.match(new RegExp(q, 'gi'));
    }
  );

  function onDelete(affinityProductCategory) {
    setAffinityCategorizations(prev =>
      prev.map(a =>
        a.id === affinityProductCategory.id ? { ...a, isLoading: true } : a
      )
    );

    localDelete(affinityProductCategory.links.self)
      .then(() => {
        setAffinityCategorizations(prev =>
          prev.filter(a => a.id !== affinityProductCategory.id)
        );
      })
      .finally(() => {
        setAffinityCategorizations(prev =>
          prev.map(a =>
            a.id === affinityProductCategory.id ? { ...a, isLoading: false } : a
          )
        );
      });
  }

  function onAdd() {
    setIsLoading(true);
    localPostJSON('/licensing/affinity_categorizations', {
      affinity_categorization: {
        division_id: division,
        department_id: department,
        apparel_class_id: apparelClass,
        affinity_product_category_id: affinityProductCategory,
      },
    })
      .then(data => {
        setAffinityCategorizations(prev => [
          ...prev,
          { ...data, isLoading: false },
        ]);
        setError(null);
      })
      .catch(err =>
        extractErrorMessage(err, 'There was an issue').then(setError)
      )
      .finally(() => {
        setDivision(null);
        setDepartment(null);
        setApparelClass(null);
        setAffinityProductCategory(null);
        setIsLoading(false);
      });
  }

  const numberOfRows = affinityCategorizations.length;

  return (
    <div>
      {error && (
        <div className="pam">
          <Notification small={true} type="alert">
            {error}
          </Notification>
        </div>
      )}

      <div className="flex-rows flex-rows--wrap react-select-form">
        <div className="flex-rows__item pvs">
          <Select
            options={props.divisions.map(buildOption)}
            onChange={e => {
              setDivision(e && e.value);
              setDepartment(null);
              setApparelClass(null);
            }}
            value={division}
            placeholder="Select a division..."
          />
        </div>
        {division && (
          <div className="flex-rows__item pvs">
            <Select
              options={props.departments
                .filter(({ divisionId }) => divisionId === division)
                .map(buildOption)}
              onChange={e => {
                setDepartment(e && e.value);
                setApparelClass(null);
              }}
              value={department}
              placeholder="Select a department..."
            />
          </div>
        )}

        {division && department && (
          <div className="flex-rows__item pvs">
            <Select
              options={props.apparelClasses
                .filter(({ departmentId }) => departmentId === department)
                .map(buildOption)}
              onChange={e => setApparelClass(e && e.value)}
              value={apparelClass}
              placeholder="Select an apparel class..."
            />
          </div>
        )}

        <div className="flex-rows__item pvs">
          <VirtualizedSelect
            options={props.affinityProductCategories.map(buildOption)}
            onChange={e => setAffinityProductCategory(e && e.value)}
            value={affinityProductCategory}
            placeholder="Select an Affinity product category..."
          />
        </div>
      </div>

      {isLoading ? (
        <Loader isActive={isLoading} />
      ) : (
        <button disabled={isInvalid} className="button mlm mtm" onClick={onAdd}>
          Add
        </button>
      )}

      <hr />

      <FilterSearch value={query} onChange={e => setQuery(e.target.value)} />

      <p className="txt-small">
        There {numberOfRows === 1 ? 'is ' : 'are '}
        <strong>
          {numberOfRows} Affinity categorization{numberOfRows === 1 ? '' : 's'}
        </strong>
        .
      </p>

      <hr />

      {filteredAffinityCategorizations.length > 0 ? (
        <Table
          data={filteredAffinityCategorizations}
          columns={columns}
          tableProps={{ className: 'table' }}
          onColumnSort={(columnId, sortDirection) =>
            setColumns(prevColumns =>
              prevColumns.map(column => {
                if (column.id === columnId) {
                  return { ...column, sort: sortDirection };
                } else {
                  return { ...column, sort: null };
                }
              })
            )
          }
        />
      ) : null}
    </div>
  );
}
