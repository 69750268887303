import PropTypes from 'prop-types';
import React from 'react';
import tenant from '../tenant';
import EmbStitchEstimateModal from './EmbStitchEstimateModal';
import classnames from 'classnames';

class JobForm extends React.Component {
  static propTypes = {
    job: PropTypes.object,
    allowCommissionUpdate: PropTypes.bool,
    showCommissionLevel: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.spLocationOne = React.createRef();
    this.spLocationTwo = React.createRef();
    this.spLocationThree = React.createRef();
    this.spLocationFour = React.createRef();
    this.isReorder = React.createRef();
    this.rush = React.createRef();
    this.rushShipping = React.createRef();
    this.commissionLevel = React.createRef();
    this.artTier = React.createRef();
    this.printColorChange = React.createRef();
    this.hasCollegeRoyalty = React.createRef();
    this.hasGreekRoyalty = React.createRef();
    this.heatPressTypeInput = React.createRef();
    this.jobNumber = React.createRef();
    this.embLocationOneStitchCount = React.createRef();
    this.embLocationOneIsPuff = React.createRef();
    this.embLocationTwoStitchCount = React.createRef();
    this.embLocationTwoIsPuff = React.createRef();
  }

  update = () => {
    let locations = [
      this.spLocationOne.current.value,
      this.spLocationTwo.current.value,
      this.spLocationThree.current.value,
      this.spLocationFour.current.value,
    ];

    let embroideryLocations =
      this.embLocationOneStitchCount.current.value &&
      this.embLocationTwoStitchCount.current.value &&
      this.embLocationOneStitchCount.current.value > 0 &&
      this.embLocationTwoStitchCount.current.value > 0
        ? [
            {
              stitchCount: this.embLocationOneStitchCount.current.value,
              embIsPuff: this.embLocationOneIsPuff.current.checked,
            },
            {
              stitchCount: this.embLocationTwoStitchCount.current.value,
              embIsPuff: this.embLocationTwoIsPuff.current.checked,
            },
          ]
        : this.embLocationOneStitchCount.current.value &&
          this.embLocationOneStitchCount.current.value > 0
        ? [
            {
              stitchCount: this.embLocationOneStitchCount.current.value,
              embIsPuff: this.embLocationOneIsPuff.current.checked,
            },
          ]
        : [];

    let job = {
      isReorder: this.isReorder.current.checked,
      locations: locations,
      rush: this.rush.current.value,
      rushShipping: this.rushShipping.current.value,
      artTier: this.artTier.current.value,
      commissionLevel: this.commissionLevel.current.value,
      jobNumber: this.jobNumber.current.value,
      heatPressLocations: this.heatPressTypeInput.current.value
        ? [this.heatPressTypeInput.current.value]
        : [],
      embroideryLocations: embroideryLocations,
      printColorChange: this.printColorChange.current.checked,
      pricingConfigVersion: this.props.job.pricingConfigVersion,
    };

    if (tenant.isCollegiate) {
      job.hasGreekRoyalty = this.hasGreekRoyalty.current.checked;
      job.hasCollegeRoyalty = this.hasCollegeRoyalty.current.checked;
    }

    this.props.onUpdateJob(job);
  };

  commissionField = () => {
    let newCommissionOptions = (
      <div className="label-select">
        <div>
          <label
            htmlFor="commission-level"
            className={this.props.job.commissionLevel ? 'show' : ''}
          >
            Commission Level
          </label>
          <select
            id="commission-level"
            defaultValue={this.props.job.commissionLevel}
            ref={this.commissionLevel}
            disabled={!this.props.allowCommissionUpdate}
          >
            <option value="0">Commission Level</option>
            <option value="0.06">6%</option>
            <option value="0.07">7%</option>
            <option value="0.08">8%</option>
            <option value="0.09">9%</option>
            <option value="0.10">10%</option>
            <option value="0.11">11%</option>
            <option value="0.12">12%</option>
          </select>
        </div>
      </div>
    );

    if (!this.props.showCommissionLevel) {
      return (
        <input
          type="hidden"
          ref={this.commissionLevel}
          value={this.props.job.commissionLevel}
        />
      );
    } else {
      return <div className="col-3">{newCommissionOptions}</div>;
    }
  };

  render() {
    let { isCollegiate } = tenant;

    return (
      <form onChange={this.update} className="form-vertical">
        <h3 className="mbs">Order Details</h3>
        <div className="row">
          <div className="col-2">
            <div>
              <label htmlFor="order-number">Order Number</label>
              <input
                type="text"
                id="order-number"
                defaultValue={this.props.job.jobNumber}
                placeholder="Order Number"
                required={true}
                ref={this.jobNumber}
                onFocus={e => {
                  e.target.select();
                }}
              />
            </div>
          </div>
          <div className="col-2">
            <label htmlFor="rush">Rush Fee</label>
            <input
              type="number"
              id="rush"
              defaultValue={this.props.job.rush}
              step="any"
              required={true}
              min="0"
              ref={this.rush}
              onFocus={e => {
                e.target.select();
              }}
            />
          </div>

          <div className="col-2">
            <div>
              <label htmlFor="rush-shipping">Rush Shipping</label>
              <input
                type="number"
                id="rush-shipping"
                defaultValue={this.props.job.rushShipping}
                step="any"
                required={true}
                min="0"
                ref={this.rushShipping}
                onFocus={e => {
                  e.target.select();
                }}
              />
            </div>
          </div>

          <div className="col-2">
            <div className="label-select">
              <div>
                <label htmlFor="art-tier">Art Tier</label>
                <select
                  id="art-tier"
                  defaultValue={this.props.job.artTier}
                  ref={this.artTier}
                >
                  <option value="art_tier_original">Original™</option>
                  <option value="art_tier_signature">Signature™</option>
                  <option value="art_tier_creative">Creative™</option>
                  <option value="art_tier_e_comm">E-Comm</option>
                </select>
              </div>
            </div>
          </div>

          {this.commissionField()}
        </div>
        <div className="row border-bottom pbl">
          <div className="col-3">
            <label>
              <input
                className="mrm"
                type="checkbox"
                defaultChecked={this.props.job.isReorder}
                ref={this.isReorder}
              />
              Reorder?
            </label>
          </div>

          <div className="col-3">
            <label>
              <input
                className="mrm"
                type="checkbox"
                defaultChecked={this.props.job.printColorChange}
                ref={this.printColorChange}
              />
              Has color changes?
            </label>
          </div>

          {isCollegiate && (
            <div className="col-3">
              <label>
                <input
                  className="mrm"
                  type="checkbox"
                  defaultChecked={this.props.job.hasGreekRoyalty}
                  ref={this.hasGreekRoyalty}
                />
                Greek Royalty?
              </label>
            </div>
          )}

          {isCollegiate && (
            <div className="col-3">
              <label>
                <input
                  className="mrm"
                  type="checkbox"
                  defaultChecked={this.props.job.hasCollegeRoyalty}
                  ref={this.hasCollegeRoyalty}
                />
                College Royalty?
              </label>
            </div>
          )}
        </div>
        <h3 className="mbn">Screen Print Locations</h3>
        <p className="txt-muted txt-small mtn">
          Note: Specialty inks are billed as 2 ink colors.
        </p>
        <div className="row border-bottom mtn pbm">
          <div className="col-3">
            <div>
              <label htmlFor="sp-location-one">
                Location #1 Ink Color Count
              </label>
              <input
                type="number"
                defaultValue={this.props.job.locations[0]}
                step="1"
                min="0"
                required={true}
                id="sp-location-one"
                ref={this.spLocationOne}
                placeholder="# of ink colors"
                onFocus={e => {
                  e.target.select();
                }}
              />
            </div>
          </div>

          <div
            className={classnames({
              'col-3': true,
              'is-disabled':
                !this.spLocationOne.current ||
                !Number.parseInt(this.spLocationOne.current.value),
            })}
          >
            <div>
              <label htmlFor="sp-location-two">
                Location #2 Ink Color Count
              </label>
              <input
                type="number"
                defaultValue={this.props.job.locations[1]}
                step="1"
                min="0"
                required={true}
                id="sp-location-two"
                ref={this.spLocationTwo}
                placeholder="# of ink colors"
                onFocus={e => {
                  e.target.select();
                }}
                disabled={
                  !this.spLocationOne.current ||
                  !Number.parseInt(this.spLocationOne.current.value)
                }
              />
            </div>
          </div>

          <div
            className={classnames({
              'col-3': true,
              'is-disabled':
                !this.spLocationTwo.current ||
                !Number.parseInt(this.spLocationTwo.current.value),
            })}
          >
            <div>
              <label htmlFor="sp-location-three">
                Location #3 Ink Color Count
              </label>
              <input
                type="number"
                defaultValue={this.props.job.locations[2]}
                step="1"
                min="0"
                required={true}
                id="sp-location-three"
                ref={this.spLocationThree}
                placeholder="# of ink colors"
                onFocus={e => {
                  e.target.select();
                }}
                disabled={
                  !this.spLocationTwo.current ||
                  !Number.parseInt(this.spLocationTwo.current.value)
                }
              />
            </div>
          </div>

          <div
            className={classnames({
              'col-3': true,
              'is-disabled':
                !this.spLocationThree.current ||
                !Number.parseInt(this.spLocationThree.current.value),
            })}
          >
            <div>
              <label htmlFor="sp-location-four">
                Location #4 Ink Color Count
              </label>
              <input
                type="number"
                defaultValue={this.props.job.locations[3]}
                step="1"
                min="0"
                required={true}
                id="sp-location-four"
                ref={this.spLocationFour}
                placeholder="# of ink colors"
                onFocus={e => {
                  e.target.select();
                }}
                disabled={
                  !this.spLocationThree.current ||
                  !Number.parseInt(this.spLocationThree.current.value)
                }
              />
            </div>
          </div>
        </div>
        <h3 className="mbn">Embroidery Locations</h3>
        <p className="txt-muted txt-small mtn">
          Not sure what your stitch count is?
        </p>
        <EmbStitchEstimateModal />
        <div className="row border-bottom pbl">
          <div className="col-3">
            <div>
              <label
                className="label-select mbn"
                htmlFor="emb-location-one-stitch-count"
              >
                Location #1 Stitch Count
              </label>
              <span className="txt-muted txt-small">Format Example: 5000</span>
              <input
                type="number"
                id="emb-location-one-stitch-count"
                defaultValue={
                  this.props.job.embroideryLocations[0]
                    ? this.props.job.embroideryLocations[0].stitchCount
                    : ''
                }
                placeholder="Ex: 5000"
                ref={this.embLocationOneStitchCount}
                onFocus={e => {
                  e.target.select();
                }}
                min="0"
              ></input>
            </div>
            <label
              className={classnames({
                'is-disabled':
                  !this.embLocationOneStitchCount.current ||
                  !Number.parseInt(
                    this.embLocationOneStitchCount.current.value
                  ),
              })}
            >
              <input
                className={classnames({
                  mrm: true,
                  'is-disabled':
                    !this.embLocationOneStitchCount.current ||
                    !Number.parseInt(
                      this.embLocationOneStitchCount.current.value
                    ),
                })}
                type="checkbox"
                defaultChecked={
                  this.props.job.embroideryLocations[0]
                    ? this.props.job.embroideryLocations[0].embIsPuff
                    : false
                }
                ref={this.embLocationOneIsPuff}
                disabled={
                  !this.embLocationOneStitchCount.current ||
                  !Number.parseInt(this.embLocationOneStitchCount.current.value)
                }
              />
              Puff Embroidery?
            </label>
          </div>
          <div className="col-3">
            <div>
              <label
                className={classnames({
                  'label-select': true,
                  mbn: true,
                  'is-disabled':
                    !this.embLocationOneStitchCount.current ||
                    !Number.parseInt(
                      this.embLocationOneStitchCount.current.value
                    ),
                })}
                htmlFor="emb-location-two-stitch-count"
              >
                Location #2 Stitch Count
              </label>
              <span className="txt-muted txt-small">Format Example: 5000</span>
              <input
                className={classnames({
                  'is-disabled':
                    !this.embLocationOneStitchCount.current ||
                    !Number.parseInt(
                      this.embLocationOneStitchCount.current.value
                    ),
                })}
                type="number"
                id="emb-location-two-stitch-count"
                defaultValue={
                  this.props.job.embroideryLocations[1]
                    ? this.props.job.embroideryLocations[1].stitchCount
                    : ''
                }
                placeholder="Ex: 5000"
                ref={this.embLocationTwoStitchCount}
                onFocus={e => {
                  e.target.select();
                }}
                disabled={
                  !this.embLocationOneStitchCount.current ||
                  !Number.parseInt(this.embLocationOneStitchCount.current.value)
                }
                min="0"
              ></input>
            </div>
            <label
              className={classnames({
                'is-disabled':
                  !this.embLocationTwoStitchCount.current ||
                  !Number.parseInt(
                    this.embLocationTwoStitchCount.current.value
                  ),
              })}
            >
              <input
                className="mrm"
                type="checkbox"
                defaultChecked={
                  this.props.job.embroideryLocations[1]
                    ? this.props.job.embroideryLocations[1].embIsPuff
                    : false
                }
                ref={this.embLocationTwoIsPuff}
                disabled={
                  !this.embLocationTwoStitchCount.current ||
                  !Number.parseInt(this.embLocationTwoStitchCount.current.value)
                }
              />
              Puff Embroidery?
            </label>
          </div>
        </div>
        <h3 className="mbn">Heat Press Locations</h3>
        <div className="row">
          <div className="col-3">
            <label className="label-select" htmlFor="heat-press-type">
              Heat Press
              <select
                id="heat-press-type"
                defaultValue={this.props.heatPressType}
                ref={this.heatPressTypeInput}
              >
                <option value="">Heat Press</option>
                <option value="name_only">Name</option>
                <option value="number_only">Number</option>
                <option value="name_and_number">Name and Number</option>
              </select>
            </label>
          </div>
        </div>
      </form>
    );
  }
}

export default JobForm;
